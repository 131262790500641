<template>
  <div class="login d-flex flex-column b-card">
    <div
      role="button"
      @click="$router.push('login')"
      class="align-self-baseline pl-1 pt-1 links"
    >
      <v-icon color="#64C7FF">mdi-arrow-left-thin</v-icon>
      назад
    </div>
    <div class="pa-5 pt-3">
      <v-card-title class="pa-0 pb-2">{{ $t('login.recoveryPassword') }}</v-card-title>

      <Input
        @change="login = $event"
        :placeholder="$t('login.login')"
        :rules="rules"
        width="300px"
      />
      <div class="d-flex justify-center mt-5">
        <Button
          color="white"
          background-color="#64C7FF"
          @click="resetPassword"
          width="250px"
          :disabled="!valid"
          class="mb-3"
        >
          <template #content>
            {{ $t('login.recovery') }}
          </template>
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import Input from '@/components/base/UI/Input'
import Button from '@/components/base/UI/Button'
import common from '@/views/public/common'

export default {
  name: 'RecoveryPassword',
  components: { Button, Input },
  mixins: [common],
  data () {
    return {}
  },
  methods: {
    async resetPassword () {
      const data = {
        data: { email: this.login }
      }
      const response = await this.$store.dispatch('api/post', { url: '/reset-password', data }, { root: true })

      if (response.success) {
        await this.$store.dispatch('systemMessages/success', 'Пароль сброшен, проверьте почту')
        await this.$router.push({ path: '/login' })
      }
      return true
    }
  }
}
</script>

<style scoped>

</style>
